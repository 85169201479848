@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");

@layer base {
  html {
    font-family: "Nunito", sans-serif;
  }
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

/* scrollbar black and thin */
::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-track {
}

::-webkit-scrollbar-thumb {
  background: #121429;
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#toggle-switch::after {
  content: url(common/assets/icons/lock-unlock.svg);
  @apply h-7 w-7 rounded-full bg-[#f5f5ff] absolute left-0 top-0 transform scale-125 transition duration-200 flex items-center justify-center text-sm;
}

#toggle-switch:checked::after {
  content: url(common/assets/icons/lock.svg);
  @apply transform scale-125 translate-x-7;
}

.rdrDefinedRangesWrapper {
  display: none;
}

#map .leaflet-div-icon {
  width: 0;
  height: 0;
  border: 0;
  padding: 0;
}

#map .leaflet-div-icon b {
  display: inline-block;
  padding: 3px;
  border: 4px solid red;
  border-radius: 3px;
  background: #dededec4;
  transform: translate(-50%, -50%);
  text-align: center;
  color: black;
  font-weight: bold;
  width: 60px;
  height: 20px;
}
